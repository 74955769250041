import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import moment from 'moment';

import { PostBasic, PostSlices } from 'fragments';

import {
  Intro,
  Image,
  Video,
  Paragraph,
  MediaParagraph
} from 'components/project';

import { Layout, SEO, Grid, Box, Text } from 'components';

const Title = Text.withComponent('h2');

const Tags = styled(Text.withComponent('span'))`
  &:not(:empty)::before {
    content: ' · ';
  }
`;

const Tag = styled.span`
  &:not(:last-child)::after {
    content: ', ';
  }
`;

const Post = props => {
  const post = props.data.prismic.allLog_items.edges.slice(0, 1).pop();
  if (!post) return null;

  const tags = post.node.tags.filter(t => {
    return t.tag && RichText.asText(t.tag.tag).length;
  });

  const slices = post.node.body1 || [];

  const date = moment(post.node.date || post.node._meta.firstPublicationDate);

  const currentPath = props.location.pathname;

  return (
    <Layout>
      <SEO title={RichText.asText(post.node.title)} />

      <Grid>
        <Box
          gridColumn={['2 / -2', '3 / span 8']}
          textAlign="center"
          mt={[7, 8]}
        >
          <Title size="2x">{RichText.asText(post.node.title)}</Title>
          <Text color="blackDimmed" mt={[2, 3]} mb={[2]}>
            Published {date.fromNow()}
            <Tags>
              {tags.map((t, i) => {
                if (RichText.asText(t.tag.tag) !== 'Important') {
                  return <Tag key={i}>{RichText.asText(t.tag.tag)}</Tag>;
                }

                return null;
              })}
            </Tags>
          </Text>
        </Box>
      </Grid>

      {slices.map((slice, i) => {
        switch (slice.type) {
          case 'intro':
          case 'intro_as_video':
            return <Intro key={i} slice={slice} pagePathname={currentPath} />;
          case 'paragraph':
            return <Paragraph key={i} slice={slice.primary} />;
          case 'image':
            return <Image key={i} slice={slice.primary} />;
          case 'image___paragraph':
            return <MediaParagraph key={i} slice={slice.primary} />;
          case 'video':
            if (slice.primary.video) {
              return <Video key={i} slice={slice.primary} />;
            } else {
              return null;
            }
          default:
            return null;
        }
      })}
    </Layout>
  );
};

Post.fragments = [PostBasic, PostSlices];

export default Post;

export const query = graphql`
  query LogItemQuery($uid: String) {
    prismic {
      allLog_items(uid: $uid) {
        edges {
          node {
            ...PostBasic
            ...PostSlices
          }
        }
      }
    }
  }
`;
